import * as React from 'react';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

const ImpressumPage = () => (
  <Layout>
    <Helmet>
      <title>Impressum</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <TopNavBar />
    <Container className="fg-content-mt">
      <div style={{ paddingTop: '3.75rem', paddingBottom: '7.5rem' }}>
        <h1>Impressum</h1>
        <h4 className="mt-4">Angaben gemäß § 5 TMG</h4>
        <p>
          <br />
          <b>FROGO AD</b> UG (haftungsbeschränkt) i. L.
          <br />
          c/o Freie Universität Berlin
          <br />
          Malteserstr. 74-100
          <br />
          12249 Berlin
          <br />
          <br />
          <b>Geschäftsführer:</b>
          <br />
          Erich Schweihofer,
          <br />
          David Seydel,
          <br />
          Karim Omar
          <br />
        </p>
        <b>Handelsregister:</b>
        <br />
        Eingetragen am Amtsgericht Charlottenburg
        <br />
        Nummer der Eintragung: HRB 194759 B<br />
        Umsatzsteuer-Identifikationsnummer: DE317292204
        <br />
        <br />
        <h4 className="mt-4">Kontakt</h4>
        <p>
          Telefon: +49 (0)30 / 403646 85
          <br />
          E-Mail: contact@frogo-ad.com
        </p>
        <br />
      </div>
    </Container>
    <Footer pullToBottom={true} />
  </Layout>
);

export default ImpressumPage;
